@font-face {
  font-family: 'DINOffcPro';
  src: url('../assets/fonts/DINOffcPro.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'DINOffcPro';
  src: url('../assets/fonts/DINOffcPro-Medi.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'DINOffcPro';
  src: url('../assets/fonts/DINOffcPro-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'DINOffcPro';
  src: url('../assets/fonts/DINOffcPro-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}